import './ParceirosCidades.css'
import Angelin from './Images/Angelin.png'
import Scomparim from './Images/Scomparim.png'
import ArmazemDoSitio from './Images/ArmazemDoSitio.png'
import EmporioVillaSantoAntonio from './Images/EmporioVillaSantoAntonio.png'
import EmporioTotalFood from './Images/EmporioTotalFood.png'

const Capivari = () => {
    return (
        <section class="containerMM">
            <div class="nossasLojasMM">

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={ArmazemDoSitio} alt='ARMAZÉM DO SÍTIO' />
                        <iframe
                            title='ARMAZÉM DO SÍTIO'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.948510724726!2d-47.5016025!3d-22.9889208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c61c5b36df3d85%3A0xb26a09fdd4c23f16!2sR.%20Arthur%20Corr%C3%AAa%20de%20Toledo%2C%20Capivari%20-%20SP%2C%2013360-000!5e0!3m2!1spt-BR!2sbr!4v1705677825394!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">ARMAZÉM DO SÍTIO</h3>

                        <div>
                            <p class="pMM" style={{maxWidth: "210px"}}>R. Arthur Corrêa de Toledo, S/N<br />
                                Bairro Pipeiro - CAPIVARI/SP - 13360-000</p>

                            <p class="pMM">TEL: (15) 99456-6648</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        {/* <img className='localImg' src='https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/46C686E3B18E2FB101C906C29D45E4832C30868A71E5EE4AAF0C9E0835229C05/scale?width=1200&aspectRatio=1.78&format=webp' /> */}
                        <iframe
                            title='PADARIA NUTRI'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.766479544493!2d-47.511432799999994!3d-22.995613099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c61d970cddaeb7%3A0x36257d523163aae2!2sAv.%20Pio%20XII%2C%20237%20-%20Raia%2C%20Capivari%20-%20SP%2C%2013360-000!5e0!3m2!1spt-BR!2sbr!4v1705679754795!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PADARIA NUTRI</h3>

                        <div>
                            <p class="pMM">Av. Pio XII, 237<br />
                                Raia - CAPIVARI/SP - 13360-000</p>

                            <p class="pMM">TEL: (19) 3491-3158</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={EmporioVillaSantoAntonio} alt='EMPÓRIO VILLA SANTO ANTONIO' />
                        <iframe
                            title='EMPÓRIO VILLA SANTO ANTONIO'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.61684424398!2d-47.5042628!3d-23.001113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c61cf5b7a2a491%3A0x6c977ae73b28704f!2sR.%20XV%20de%20Novembro%2C%20377%20-%20Centro%2C%20Capivari%20-%20SP%2C%2013360-000!5e0!3m2!1spt-BR!2sbr!4v1705679923435!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">EMPÓRIO VILLA SANTO ANTONIO</h3>

                        <div>
                            <p class="pMM">R. XV de Novembro, 377<br />
                                Centro - CAPIVARI/SP - 13360-000</p>

                            <p class="pMM">TEL: (19) 3492-7467</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={EmporioTotalFood} alt='EMPÓRIO TOTAL FOOD'/>
                        <iframe
                            title='EMPÓRIO TOTAL FOOD'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.771164028529!2d-47.50535559999999!3d-22.9954409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c61c5e8840923f%3A0xe11843f307a7b6cd!2sR.%20Padre%20Fabiano%2C%201009%20-%20Centro%2C%20Capivari%20-%20SP%2C%2013360-000!5e0!3m2!1spt-BR!2sbr!4v1705680026957!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">EMPÓRIO TOTAL FOOD</h3>

                        <div>
                            <p class="pMM">R. Padre Fabiano, 1009<br />
                                Centro - CAPIVARI/SP - 13360-000</p>

                            <p class="pMM">TEL: (19) 3492-7717</p>
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Angelin} alt='PADARIA & CONFEITARIA ANGELIN'/>
                        <iframe
                            title='PADARIA & CONFEITARIA ANGELIN'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.2653148741542!2d-47.52152960000001!3d-23.014028699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c61c84b7afb9d5%3A0x7c98457caf1462b9!2sAv.%20Jos%C3%A9%20Annichinno%2C%201843%20-%20Vila%20Bela%20Vista%2C%20Capivari%20-%20SP%2C%2013360-000!5e0!3m2!1spt-BR!2sbr!4v1709652049123!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">PADARIA & CONFEITARIA ANGELIN</h3>

                        <div>
                            <p class="pMM">Av. José Annicchino, 1843<br />
                                Vila Bela vista - CAPIVARI/SP - 13360-000</p>

                            {/* <p class="pMM">TEL: (19) 3492-7717</p> */}
                        </div>
                    </div>
                </div>

                <div class="conteudoMM">
                    <div class="mapaMM">
                        <img className='localImg' src={Scomparim} alt='SCOMPARIM SABORES DE MINAS'/>
                        <iframe
                            title='SCOMPARIM SABORES DE MINAS'
                            height="300"
                            width="100%"
                            style={{ border: 0, borderRadius: '25px 25px 0px 0px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.3674612582304!2d-47.5062087!3d-23.0102764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c61ced6ef6f405%3A0x876819cfdbe62464!2sAv.%20Brg.%20Faria%20Lima%2C%20614%20-%20Lot.%20Jd%20Sta%20Maria%2C%20Capivari%20-%20SP%2C%2013369-040!5e0!3m2!1spt-BR!2sbr!4v1709652626500!5m2!1spt-BR!2sbr"
                            allowfullscreen
                        ></iframe>
                    </div>

                    <div class="descricaoMM">
                        <h3 class="h3MM">SCOMPARIM SABORES DE MINAS</h3>

                        <div>
                            <p class="pMM">Av. Brigadeiro Faria Lima, 614<br />
                                Jd. Santa maria - CAPIVARI/SP - 13369-040</p>

                            {/* <p class="pMM">TEL: (19) 3492-7717</p> */}
                        </div>
                    </div>
                </div>


            </div>
        </section>

    )
}

export default Capivari